import { Controller } from "@hotwired/stimulus"

// images supported from the retrieved json `type` attribute
const SUPPORTED_IMAGES_TYPES = ['image', 'gifv'];

function truncate_content(str, n) {
  return (str.length > n) ? str.slice(0, n-1) + '&hellip;' : str;
};

export default class extends Controller {
  static values = {
    urls: Array, // API urls of Mastodon threads
    toots: Array,  // retrieved toots to display
    quantity: { type: Number, default: 10 },  // number of toots to display
    refreshTime: { type: Number, default: 30 },  // time to refresh in seconds
  }
  static targets = ["toot", "template"]

  connect() {
    this.fetchMastodonToots();
    setInterval(() => {
      this.fetchMastodonToots()
    }, this.refreshTimeValue * 1000)
  }

  fetchMastodonToots() {
    Promise.all(this.urlsValue.map(url => fetch(url).then(resp => {
      if (resp.ok) {
        return resp.json();
      }
      // Http errors trigger the promise to be rejected
      // and are logged in the console.
      const error = resp.status;
      return Promise.reject(error + " : " + url);
    }).catch(error => {
      console.error('There was an error!', error);
    })))
      .then(v => v.flat())
      .then(v => this.selectToots(v))
  }

  selectToots(toots) {
    toots.sort((a, b) => a.created_at > b.created_at ? -1: 1);  // sort by date
    this.tootsValue = toots.slice(0, this.quantityValue)
  }

  tootsValueChanged(toots) {
    this.clearAllToots();
    toots.forEach((toot) => {
      // In case of http error when fetching, toots might be empty.
      // Such toots are not to be rendered.
      if(toot){
        this.renderToot(toot);
      } 
    });
  }

  renderToot(toot) {
    const template = this.templateTarget
    const clone = template.content.cloneNode(true);
    clone.querySelector("[name='username']").textContent = toot.account.username;
    clone.querySelector("[name='content']").innerHTML = truncate_content(toot.content, 128);
    const date = new Date(toot.created_at);
    clone.querySelector("[name='date']").textContent = date.toLocaleDateString();
    clone.querySelector("[name='user-url']").href = toot.account.url;
    clone.querySelector("[name='toot-url']").href = toot.url;
    if (toot.media_attachments.length > 0 && SUPPORTED_IMAGES_TYPES.includes(toot.media_attachments[0].type)) {
      clone.querySelector("[name='img']").src = toot.media_attachments[0].preview_url;
    }
    this.element.appendChild(clone);
  }

  clearAllToots() {
    this.tootTargets.forEach((element) => {
      element.remove()
    })
  }
}
